import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"; 
import FrontLayoutRoutes from "./frontend/frontRoutes"; 
import {Confirmation} from "../components";
import { ToastContainer } from 'react-toastify';
// import Regestration from '../container/frontend/regestration';
  
const Home =  React.lazy(() => import('../container/frontend/home'));
const Regestration =  React.lazy(() => import('../container/frontend/regestration'));
const Start =  React.lazy(() => import('../container/frontend/start'));
const Campaign=React.lazy(()=>import('../container/frontend/campaign'))
const Explore=React.lazy(()=>import('../container/frontend/start/explore'))

function Routes() {
  // var url = document.URL.split("/");
  // //console.log("url ",url);
  // if(url.length>3 && url[3]=="logout"){
  //   localStorage.clear();
  //   let domLink=url[0]+"//"+url[2];
  //   //console.log('domLink ',domLink);
  //   window.location.replace(domLink);
  // }
  return (
    <Router>
         <ToastContainer
      data-testid="app-component"
      position="top-right"
      autoClose={5000}
      draggable
    />
      <Switch>
        <FrontLayoutRoutes exact path="/"  routeName={'home'} render={matchprops => (<Home {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/registration"  routeName={'registration'} render={matchprops => (<Regestration  {...matchprops} />)} />
        <FrontLayoutRoutes exact path="/start/:id"  routeName={'start'} render={matchprops => (<Start {...matchprops}   />)} />
        <FrontLayoutRoutes exact path="/campaign"  routeName={'campaign'} render={matchprops => (<Campaign {...matchprops}   />)} />
        <FrontLayoutRoutes exact path="/explore"  routeName={'explore'} render={matchprops => (<Explore {...matchprops}   />)} />
      </Switch>
      <Confirmation/>
    </Router>
  )
    ;
}

export default Routes;