import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const SettingContext = createContext();

export const useSettingContext = () => useContext(SettingContext);

export const SettingProvider = ({ children }) => {
  const [settings, setSettings] = useState([]);
  const [user,setUser]=useState(JSON.parse(localStorage.getItem("user")));
  const [categories,setCategories]=useState([]);

  // Function to fetch data from API
  const fetchSettings = () => {
    axios.get( `https://tk35rbvww4ud.atlas.thrinacia.com/api/service/restv1/portal/setting`)
        .then((res) => {
            setSettings(res?.data || []);
        })
        .catch((err) => {
            console.log(err, 'error');
        });
};
const fetchCategories = () => {
  axios.get( `https://tk35rbvww4ud.atlas.thrinacia.com/api/service/restv1/portal/category`)
      .then((res) => {
          setCategories(res?.data || []);
      })
      .catch((err) => {
          console.log(err, 'error');
      });
};
const fetchUserFromLocalStorage=()=>{
    const userData=JSON.parse(localStorage.getItem("user"));
    if(userData) setUser(userData);
}
const setUserToLocalStorage=(userData)=>{
    if(!userData) return;
    localStorage.setItem("user",JSON.stringify(userData));
    setUser(userData);
}

  useEffect(() => {
    fetchUserFromLocalStorage()
    fetchSettings()
    fetchCategories()
  }, []);

  // Value to provide to consumers
  const value = { settings,user,setUserToLocalStorage,categories };

  return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>;
};
