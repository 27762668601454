import HttpService from "./http.service";

class AuthService extends HttpService {
    login = (data) => this.post("auth/login", data);

    signup = (data) => this.post(`register`, data);

    logout = (data) => this.get("logout");

    sendOtp = (data) => this.post(`auth/login`,data);

    sendQuery = (data) => this.post(`public/auth/manageQueries`,data);

    verifyOtp = (data) => this.post(`auth/verify-otp`,data);
}

export default new AuthService();
