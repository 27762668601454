

import Routes from "./routes/routes";
import { Provider } from 'react-redux';
import PersistedStore from "./store";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { SettingProvider } from "./context/settingContext";
// import { ToastContainer } from "react-toastify/dist";

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif'
    // fontFamily: 'Flexo'
  },
});
const store = PersistedStore.getDefaultStore().store;

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <SettingProvider>
        <Provider store={store}>
          <Routes />
        </Provider></SettingProvider>
    </ThemeProvider>
  )
}

export default App;

// https://www.codementor.io/blog/react-optimization-5wiwjnf9hj#.YbD42UeP0l4.linkedin
