import { 
  dashboardIcon, 
  userIcon,
} from '../assets/images/img';

let isLocal=true;
let cmsUrl="";
let backendUrl="";

if(isLocal){ 
  cmsUrl = `http://localhost:3000`; 
  backendUrl = `https://origin.thrinacia.com`;  
}
else{
  cmsUrl = `http://wapda.rockvillegroup.com:3333`; 
  backendUrl= `https://origin.thrinacia.com`; 
}
export const CMS_DOMAIN_PREFIX =cmsUrl;
export const APP_DOMAIN_PREFIX = backendUrl; 

export const API_URL = `${APP_DOMAIN_PREFIX}/api/service/restv1`;
export const FILE_BASE_URL = 'http=//localhost=9000'; 

export const LABELS = {
  GO_BACK: '← Back',
  LOGOUT: '↶ Logout',
  LOGIN: 'Login',
  SIGNUP: 'Sign Up',
  REGISTER: 'Create User',
  EMAIL: 'Email Address',
  NAME: 'Username',
  FULL_NAME: 'Full Name',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm Password',
  INVALID_MOBILE: 'Invalid mobile number'
};
export const PASSWORD = {
  passwordLength: 6,
  passwordLengthError: 'password is to short'
}
export const REGISTER = {
  SUCCESS_HEADER: "Success",
  SUCCESS_MESSAGE: "User Created Successfully!",
  FAILURE_HEADER: "Failure",
  FAILURE_MESSAGE: "Cannot Create User! User may already have been created with the given email!"
};
export const REGEXP_EMAIL = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2;3})+$/;
export const C_OTC_STORAGE = 'c_d_storage';


// export const sidebarTabsList = [
//   { text: 'dashboard', key: 'dashboard', icon: dashboardIcon },
//   { text: 'user management', key: 'users', icon: userIcon }, 
// ];

export const jobStatus = [
  'pending', 'assigned', 'in route', 'delivered', 'completed',
];

export const userRoles = [
  { text: 'Admin', value: '1' }
]

 
export const YesNoOption = [
  { text: 'Yes', value: '1' },
  { text: 'No', value: '0' },
]
 
export function getFormattedDate(date) {
  const date1 = new Date(date);                 // {object Date}
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  let year = date1.getFullYear();
  let month = monthNames[date1.getMonth()];
  let day = date1.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
}
 
export const statusFailureCase = [3, 6, 8, 9, 11]
export const statusFailureCase1 = [5, 6, 7, 8, 9, 10, 11]

export const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const mobileNumberRegex = /^[\+]?[(]?[0-9]{2}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5,}$/im

 